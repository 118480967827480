<template>
  <article class="main-slider">
    <div class="main-slider__wrapper">
      <div class="main-slider__slider">
        <div
          ref="sliderWidth"
          class="main-slider__slides"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          <div
            v-for="(item, index) in filteredItems"
            :key="index"
            class="main-slider__slide"
          >
            <div class="main-slider__slide-wrapper">
              <div class="main-slider__main-info">
                <NuxtLink
                  :to="`${getUserLanguage.urlPrefix}/${
                    item.fields.url[getUserLanguage.name]
                  }`"
                  class="main-slider__title"
                  :style="{ '--colorTitle': getColorTitle(item) }"
                >
                  {{ item.fields.title?.[getUserLanguage.name] }}
                </NuxtLink>

                <div class="main-slider__image">
                  <span class="main-slider__image-border"></span>
                  <img
                    v-if="item.fields.imgMain"
                    :src="
                      useNormalizeCtfUrl(
                        item.fields.imgMain.ru.fields.file.ru.url,
                      )
                    "
                    :alt="item.fields.title?.[getUserLanguage.name]"
                  />
                  <img
                    v-else
                    src="/img/photo-plug.svg"
                    :alt="item.fields.title?.[getUserLanguage.name]"
                  />
                </div>

                <p
                  class="main-slider__subtitle"
                  :style="{ '--colorDescription': getColorDescription(item) }"
                >
                  {{ item.fields.description?.[getUserLanguage.name] }}
                </p>

                <NuxtLink
                  class="main-slider__details"
                  :style="[
                    { '--color-text': getColorBtnText(item) },
                    { '--bg-color': getColorBtnBg(item) },
                  ]"
                  :to="`${getUserLanguage.urlPrefix}/${
                    item.fields.url[getUserLanguage.name]
                  }`"
                >
                  {{ item.fields.btn?.[getUserLanguage.name] }}
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-slider__pagination">
        <ul class="main-slider__nav">
          <li
            v-for="(slide, index) in items"
            :key="index"
            class="main-slider__nav-item"
            :class="{ active: index === counter }"
            @click="activePagination(index)"
          />
        </ul>
      </div>

      <div class="main-slider__buttons">
        <PaginationBtnArrow :direction-right="false" @click="prevSlide" />

        <PaginationBtnArrow :direction-right="true" @click="nextSlide" />
      </div>
    </div>
  </article>
</template>

<script setup>
import PaginationBtnArrow from "~~/modules/shared/buttons/PaginationBtnArrow.vue";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { addScopedEventListener } from "~~/utils/eventListener";
import { useContentfulStore } from "~/store/contentfulStore";
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const contentful = useContentfulStore();
await contentful.fetchSlider();

const items = contentful.getSlider;
const sliderWidth = ref(null);
const bgImage = ref("black");
const bgColor = ref("black");
const borderColor = ref("#f36c21");

const filteredItems = ref([]);

const counter = ref(0);
const sliderLength = ref(0);
const slideWidth = ref(0);
const translateX = ref(0);
const translateXVar = ref("");

const activeTouches = ref(false);
const mobileTranslateX = ref(0);
const startPosition = ref(0);
const difference = ref(0);
const interval = ref(-1);

function getColorTitle(item) {
  return item.fields.colorTitle ? item.fields.colorTitle.ru : "white";
}

function getColorBtnText(item) {
  return item.fields.colotBtn ? item.fields.colotBtn.ru : "white";
}

function getColorBtnBg(item) {
  return item.fields.colorBtnBack ? item.fields.colorBtnBack.ru : "#f36c21";
}

function getColorDescription(item) {
  return item.fields.colorText ? item.fields.colorText.ru : "white";
}

function getBgImage(item) {
  return item.fields.backgroundImage
    ? `url('${useNormalizeCtfUrl(
        item.fields.backgroundImage.ru.fields.file.ru.url,
      )}')`
    : item.fields.colorBack.ru;
}

function getBgColor(item) {
  return item.fields.colorBack?.ru ? item.fields.colorBack?.ru : "black";
}

function getSliderValues() {
  filterItems();
  sliderLength.value = filteredItems.value.length;
  slideWidth.value = sliderWidth.value.scrollWidth / sliderLength.value;
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  actualTransition();
}

function actualTransition() {
  translateX.value = slideWidth.value * counter.value;
  translateXVar.value = `-${translateX.value}px`;
}

function filterItems() {
  filteredItems.value = items.sort(
    (a, b) => a.fields.position.ru - b.fields.position.ru,
  );
  return filteredItems.value;
}

function prevSlide() {
  if (counter.value > 0) {
    counter.value--;
    translateX.value = slideWidth.value * counter.value;
  } else {
    counter.value = sliderLength.value - 1;
    translateX.value = counter.value * slideWidth.value;
  }
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  translateXVar.value = `-${translateX.value}px`;
}

function nextSlide() {
  if (counter.value < sliderLength.value - 1) {
    counter.value++;
    translateX.value = slideWidth.value * counter.value;
  } else {
    counter.value = 0;
    translateX.value = 0;
  }
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  translateXVar.value = `-${translateX.value}px`;
}

function activePagination(index) {
  counter.value = index;
  translateX.value = slideWidth.value * counter.value;
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  translateXVar.value = `-${translateX.value}px`;
}

function handleTouchStart(event) {
  if (window.innerWidth < 1024) {
    activeTouches.value = true;
    mobileTranslateX.value = event.touches[0].clientX;
    startPosition.value = event.touches[0].clientX;
  }
}

function handleTouchMove(event) {
  if (window.innerWidth < 1024) {
    const positionMove = event.touches[0].clientX;
    const diff = positionMove - mobileTranslateX.value;
    const fingerSpace = 30;

    if (
      startPosition.value - positionMove < fingerSpace &&
      startPosition.value - positionMove > -fingerSpace
    ) {
      return false;
    } else {
      if (activeTouches.value) {
        if (!mobileTranslateX.value) return false;

        difference.value = diff;
        difference.value > 0 ? prevSlide() : nextSlide();

        mobileTranslateX.value = null;
      }
    }
  }
}

function handleTouchEnd() {
  if (window.innerWidth < 1024) {
    activeTouches.value = false;
  }
}

function endInterval(interval) {
  clearInterval(interval);
}

watch(counter, () => {
  endInterval(interval.value);
  interval.value = setInterval(nextSlide, 5000);
});

onMounted(() => {
  getSliderValues();
  addScopedEventListener(window, "resize", getSliderValues);
});

onUpdated(() => {
  getSliderValues();
});
</script>

<style lang="scss" scoped>
.main-slider {
  padding: 0 16px;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: v-bind(bgImage);
  background-color: v-bind(bgColor);

  transition: background 0.4s ease-in-out;

  @include bigMobile {
    padding: 0;
  }

  &__wrapper {
    @extend %width-main;
    min-height: 460px;

    position: relative;

    @include flex-container(column, center, center);

    padding: 24px 0;
    margin: 0 auto;

    overflow: hidden;

    @include bigMobile {
      gap: 28px;
    }
  }

  &__slider {
    @extend %width-main;
  }

  &__slides {
    @include flex-container(row, flex-start, center);

    transform: translateX(v-bind(translateXVar));

    transition: transform 0.5s ease-in-out;
  }

  &__slide {
    flex: 0 0 100%;

    padding: 0 64px;

    @include bigMobile {
      padding: 0;
    }
  }

  &__slide-wrapper {
    max-width: 1130px;
    width: 100%;

    position: relative;

    margin: auto;

    @include bigMobile {
      padding: 0 16px;
    }
  }

  &__main-info {
    max-width: 460px;
    width: 100%;

    @include flex-container(column, flex-start, flex-start);

    gap: 16px;

    @include bigMobile {
      max-width: 100%;

      @include flex-container(column, center, center);
    }
  }

  &__title {
    --colorTitle: white;

    @include font(36, 43, 700);

    color: var(--colorTitle);

    @include bigMobile {
      @include font(24, 34, 700);
      letter-spacing: 0.02em;
      text-align: center;
    }
  }

  &__image {
    max-width: 404px;
    width: 100%;
    height: 404px;

    position: absolute;
    top: 50%;
    right: 0;

    @include flex-container(column, center, center);

    transform: translateY(-50%);

    padding: 16px;

    margin: auto;

    & .main-slider__image-border {
      width: 100%;
      height: 100%;

      position: absolute;

      @include bigMobile {
        display: none;
      }

      &::before,
      &::after {
        content: "";

        width: 64px;
        height: 64px;

        position: absolute;
        z-index: 0;

        display: block;

        border: 1px solid v-bind(borderColor);
      }

      &::before {
        border-left-color: transparent;
        border-bottom-color: transparent;

        right: 0;
        top: 0;
      }

      &::after {
        border-right-color: transparent;
        border-top-color: transparent;

        left: 0;
        bottom: 0;
      }
    }

    &::before,
    &::after {
      content: "";

      width: 64px;
      height: 64px;

      position: absolute;
      z-index: 0;

      display: block;

      border: 1px solid v-bind(borderColor);

      @include bigMobile {
        display: none;
      }
    }

    &::before {
      border-right-color: transparent;
      border-bottom-color: transparent;

      left: 0;
      top: 0;
    }

    &::after {
      border-left-color: transparent;
      border-top-color: transparent;

      right: 0;
      bottom: 0;
    }

    @include bigMobile {
      height: auto;

      position: static;

      @include flex-container(column, center, center);

      transform: translateY(0);

      padding: 0;
    }
  }

  &__subtitle {
    --colorDescription: white;

    @include font(16, 22, 400);
    color: var(--colorDescription);
    letter-spacing: 0.02em;

    @include bigMobile {
      text-align: center;
    }
  }

  &__details {
    --color-text: white;
    --bg-color: var(--color-primary-base);

    max-width: 236px;
    width: 100%;

    @include flex-container(row, center, center);

    position: relative;

    @include font(18, 24);
    letter-spacing: 0.02em;
    color: var(--color-text);

    background-color: var(--bg-color);

    border-radius: 8px;

    padding: 8px 16px;

    @include bigMobile {
      align-self: flex-start;

      margin: 0 auto;
    }
  }

  &__pagination {
    position: absolute;
    bottom: 16px;

    @include bigMobile {
      position: static;
    }
  }

  &__nav {
    @include flex-container(row, flex-start, center);

    gap: 16px;
  }

  &__nav-item {
    width: 14px;
    height: 14px;

    background-color: white;
    border-radius: 50%;

    cursor: pointer;

    transition: background-color 0.2s ease-in-out;

    &.active {
      background-color: var(--color-primary-base);
    }

    @include bigMobile {
      width: 12px;
      height: 12px;
    }
  }

  &__buttons {
    width: 100%;

    @include flex-container(row, space-between, center);

    position: absolute;
    top: 50%;
    z-index: 80;

    transform: translateY(-50%);

    @include bigMobile {
      display: none;
    }
  }
}
</style>
